<template functional>
	<div :class="[data.class, data.staticClass]" class="flex items-center justify-center h-full">
		<nuxt-link :to="props.href" class="block">
			<img :src="props.image.mobile.src" :alt="props.image.alt" :width="props.image.mobile.width" :height="props.image.mobile.height" fetchpriority="high" class="w-72 object-cover h-auto tablet:w-auto tablet:h-88" />
		</nuxt-link>
	</div>
</template>

<script>
export default {
	inheritAttrs: false,
	props: {
		image: {
			type: Object,
			required: true,
		},
		href: {
			type: [String, Object],
			required: true,
		},
	},
};
</script>
