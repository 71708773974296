<template>
	<div v-once class="h-full">
		<!-- endorsements mobile. tablet. animated. -->
		<div class="relative h-full desktop:hidden">
			<endorser
				v-for="(endorsement, index) in mobileEndorsements"
				:key="endorsement.title"
				:class="`top-0 left-0 absolute w-full h-full p-14 bg-white fadeCycle-item-${index + 1}`"
				:href="endorsement.href"
				:image="{ alt: endorsement.title, mobile: { src: endorsement.src, width: endorsement.width, height: endorsement.height }, }"
			/>
		</div>

		<!-- endorsements desktop. no animation. -->
		<div class="hidden desktop:flex desktop:justify-between desktop:h-full divide-x divide-black/10">
			<endorser
				v-for="endorsement in desktopEndorsements"
				:key="endorsement.title"
				class="w-full p-14"
				:href="endorsement.href"
				:image="{ alt: endorsement.title, mobile: { src: endorsement.src, width: endorsement.width, height: endorsement.height }, }"
			/>
		</div>
	</div>
</template>

<script>
import endorser from "~/components/endorser.vue";

const endorsementsUrl = '/about-us/endorsements/'

const endorsement = {
	tiaki: {
		title: "Tiaki Promise Awards",
		href: endorsementsUrl,
		src: "/images/Tiaki.png",
		width: 55,
		height: 88
	},
	canstar: {
		title: "Canstar Blue Award Winners",
		href: endorsementsUrl,
		src: "/images/Awards_Canstar_Blue.png",
		width: 82,
		height: 88
	},
	toitu: {
		title: "Toitu Net Carbonzero Certified",
		href: endorsementsUrl,
		src: "/images/Toitu.png",
		width: 54,
		height: 88
	},
	aamember: {
		title: "AA Member Benefit",
		href: '/deals/promotion/?code=aamember',
		src: "/images/AA_Member_Benefit.png",
		width: 66,
		height: 88
	},
	qualmark: {
		title: "Qualmark Gold Awards",
		href: endorsementsUrl,
		src: "/images/Awards_Qualmark.jpg",
		width: 90,
		height: 88
	},
	tourism: {
		title: "NZ Tourism Industry",
		href: endorsementsUrl,
		src: "/images/Awards_TIA.jpg",
		width: 120,
		height: 120
	},
}

export default {
	components: {
		endorser,
	},
	computed: {
		mobileEndorsements() {
			return Object.values(endorsement)
		},
		desktopEndorsements() {
			return [endorsement.tiaki, endorsement.qualmark, endorsement.aamember]
		}
	}
};
</script>

<style lang="scss">
@use "sass:math";

// --------------------------------
// Pure CSS Image Cycle
// Requires Sass and Autoprefixer
// Created by @tedmw - 12/17/2014
// --------------------------------

//number of slides
$slideCount: 6;

// Duration of transition
$fadeCycle-transition: 1s;

// Delay between transitions
$fadeCycle-wait: 3s;

// Generate animation
//-------------------
$fadeCycle-length: $fadeCycle-transition + $fadeCycle-wait;

$fadeCycle-duration: $slideCount * ($fadeCycle-transition + $fadeCycle-wait);

@keyframes fadeCycle-duration {
	0% {
		opacity: 0;
		z-index: 2;
	}
	// Fade in after $fadeCycle-transition seconds
	#{percentage(math.div($fadeCycle-transition, $fadeCycle-duration))} {
		opacity: 1;
	}
	// Finish next transition
	#{percentage(math.div(($fadeCycle-transition + $fadeCycle-length), $fadeCycle-duration))} {
		opacity: 1;
	}
	// Begin fade out after next transition finishes
	#{percentage(math.div(($fadeCycle-transition + $fadeCycle-length), $fadeCycle-duration)) + 1%} {
		opacity: 0;
		z-index: 1;
	}
	100% {
		opacity: 0;
	}
}

// Do not include animation-delay so that it can be set separately for each item
%fadeCycle-duration {
	animation-duration: $fadeCycle-duration;
	animation-fill-mode: both;
	animation-iteration-count: infinite;
	animation-name: fadeCycle-duration;
	animation-timing-function: ease-in;
	transition: opacity #{$fadeCycle-transition} ease-in;
}

// Apply animations to each item with staggered animation-delay
@for $i from 1 through $slideCount {
	.fadeCycle-item-#{$i} {
		@extend %fadeCycle-duration;
		animation-delay: ($i - 1) * $fadeCycle-length;
	}
}
</style>
